import React from "react";
import { Logo } from "../../components/Components";
import { Container } from "./Style";

export function ResearchLandingPage() {
  return (
    <Container>
      <h1
        id="0xparc-research"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <Logo width="1em" height="1em" />
        0xPARC Research
      </h1>
      <p>
        We are 0xPARC Research. We aspire to expand people's imagination for
        what cryptography enables.
      </p>
      <p>
        We are a small group that writes open-source tooling and supports
        innovative research.
      </p>
      <p>
        At the moment, we are focused on a deep but important branch of the
        modern cryptography tech tree:
      </p>
      <ul>
        <li>
          <strong>zkSNARKs</strong>
          <ul>
            <li>
              <strong>Proof Recursion</strong>
              <ul>
                <li>
                  <strong>Nova-ish proving systems</strong>:{" "}
                  <a
                    href="https://0xparc.notion.site/Team-Novi-2d81bc06b0aa4c99b61a9ee06166b3b6"
                    target="_blank"
                  >
                    Team Novi
                  </a>
                </li>
              </ul>
            </li>
            <li>zkDSLs</li>
            <li>hardware acceleration</li>
            <li>connective tissues (lookups)</li>
            <li>crypto primitives (ECs, UO groups, lattices,…)</li>
            <li>etc…</li>
          </ul>
        </li>
        <li>(non-interactive) MPC</li>
        <li>PIR</li>
        <li>TEE</li>
        <li>exotic encryption</li>
        <li>etc…</li>
      </ul>
      <p>
        Eventually, we hope to grow to explore a larger breadth of this
        (incomplete) tree with as much attention.
      </p>
      <p>
        See our past work on the <a href="/output">/output</a> page.
      </p>
      <br />
      <hr />
      <h1 id="work-at-0xparc-research">Work at 0xPARC Research</h1>
      <p>
        We are actively hiring for <strong>researchers</strong>. Email
        nalin@0xparc.org to apply.
      </p>
      <p>
        <strong>Research</strong>
      </p>
      <p>
        By default, we provide short to medium term grants to values-aligned
        work within our current scope. Tell us what you&rsquo;d like to do (or
        would like us to do) with a short proposal if this fits. The more
        ambitious and controversial, the better.
      </p>
      <p>
        For longer term prospects, while our current capacity is rather limited,
        we intend to hire excellent researchers in-house.
      </p>
    </Container>
  );
}
