import React from "react";
import { Logo } from "../../components/Components";
import { Container } from "./Style";

export function ResearchOutput() {
  return (
    <Container>
      <h1
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "8px",
        }}
        id="0xparc-research"
      >
        <Logo width="1em" height="1em" />
        0xPARC Research Output
      </h1>
      <p>
        Learn more about us on the <a href="/">home page</a>.
      </p>
      <ul>
        <li>
          {" "}
          May 2023
          <ul>
            <li>
              <a href="https://0xparc.notion.site/Team-Novi-2d81bc06b0aa4c99b61a9ee06166b3b6">
                Announcing Team Novi
              </a>
            </li>
          </ul>
        </li>
        <li>
          {" "}
          April 2023
          <ul>
            <li>
              <a href="https://0xparc.notion.site/Zuzalu-ZK-Week-Nova-Track-Output-1be8fea8301b456bb645b03af2c94d42">
                Zuzalu ZK Week Nova Track Output
              </a>
            </li>
          </ul>
        </li>
        <li>
          {" "}
          November 2022
          <ul>
            <li>
              <a href="https://0xparc.org/blog/parc-squad">
                Announcing PARC Squad
              </a>
            </li>
          </ul>
        </li>
        <li>
          {" "}
          September 2022
          <ul>
            <li>
              <a href="https://0xparc.org/blog/groth16-recursion">
                Recursive zkSNARKs: Exploring New Territory
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </Container>
  );
}
