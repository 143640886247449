import styled from "styled-components";

export const Container = styled.div`
  min-height: 100%;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-family: "Lucida Console", monospace;
  max-width: min(72rem, 90%);
  margin-right: auto;
  margin-left: auto;
  padding-top: 1rem;

  h1 {
    font-family: "Lucida Console", monospace;
  }

  strong {
    font-weight: bold;
    font-family: "Lucida Console", monospace;
  }

  ul,
  ul ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul ul {
    margin-left: 10px;
  }

  ul li {
    margin: 0;
    padding: 0 7px;
    line-height: 20px;
    border-left: 1px solid rgb(100, 100, 100);
  }

  ul li:last-child {
    border-left: none;
  }

  ul li:before {
    position: relative;
    top: -0.3em;
    height: 1em;
    width: 12px;
    color: white;
    border-bottom: 1px solid rgb(100, 100, 100);
    content: "";
    display: inline-block;
    left: -7px;
  }

  ul li:last-child:before {
    border-left: 1px solid rgb(100, 100, 100);
  }
`;
